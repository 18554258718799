<template>
  <div class="winner-page__main-content ">
    <div class="email-info-message-background--padding">
      <div class="expired-container-wrap">

        <CodeFillingWinnerformHero :winnerType="winnerType" :needBtn="false" />

        <!-- Már regisztrált -->
        <div
            class="expired-container second-background-inner--winner-messsage text-center form--status__content--expired"
            v-if="alreadyRegistered"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 col-lg-10 offset-lg-1">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin--big text-red margin-top-large">
                    {{tr('winning-form-result-title-already')}}
                  </div>
                  <!--<div class="codefilling-text">
                    <div class="text-red">
                      {{tr('winning-form-result-title-already-sub')}}                
                    </div>
                  </div>
                  <div class="text-container email-info-text--small">
                    {{tr('winning-form-result-noveld-nyeresi-eselyed')}}
                  </div>
                  <div class="text-container winner-form-divider"></div>-->
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToForm">
                      {{tr('winning-form-result-new-code-btn')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Sikeres adatfeltöltés -->
        <div id="success-data-upload"
             class="expired-container second-background-inner  text-center form--status__content--expired"
             v-if="!uploadedError && !alreadyRegistered && !formExpired && !formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin--big  margin-top-large">{{tr('winning-form-result-success-title')}}</div>
                  <!--<div class="text-center mb-2">
                    <img src="@/assets/img/form/ic-success.svg" class="img-fluid nem-nyert-img"/>
                  </div>-->
                  <div class="codefilling-text">
                    <div style="color: #B3C34A;">
                      <h3>{{tr('winning-form-result-success-sub-title')}}</h3>
                    </div>
                  </div>
                  <div class="text-container email-info-text--small">
                    {{tr('winning-form-result-success-text-1')}}
                    <!--<div class=" pt-1">
                      <p class="text-container mt-3 mb-1 email-info-text--small">
                        {{tr('winning-form-result-success-text-2')}}
                      </p>
                    </div>-->
                  </div>
                  <div class="text-container winner-form-divider--custom"></div>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mb-5 btn-primary--large mt-4" @click="backToForm">
                      {{tr('winning-form-result-new-code-btn')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- lejárt az idő -->
        <div
            class="expired-container second-background-inner  text-center form--status__content--expired"
            v-if="formExpired"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin--big  margin-top-large">
                    {{tr('winning-form-result-out-of-time-title')}} 
                  </div>                 
                  <div class="email-info-text--small text-container">
                    {{tr('winning-form-result-out-of-time-text-1-1')}}<a
                      :href="baseUrl+`documents/Jatekszabalyzat_${$store.state.currentLang}.pdf?` + jatekszabalyTimeStamp"
                      style="text-decoration:underline; color:black; font-weight:bold"
                      target="_blank">{{tr('winning-form-result-out-of-time-text-1-2')}}</a> {{tr('winning-form-result-out-of-time-text-1-3')}}<br/><br/>
                  </div>
                  <!--<p class="form-expired-bold-text" style="font-weight:bold; margin-bottom:20px;">{{tr('winning-form-result-out-of-time-text-2')}}</p>-->
                  <div class="text-container winner-form-divider--custom"></div>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mt-3 btn-primary--large" @click="backToForm">
                      {{tr('winning-form-result-new-code-btn')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Érvénytelen pályázat -->
        <div
            class="expired-container second-background-inner  text-center form--status__content--expired"
            v-if="formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin--big  margin-top-large">{{tr('winning-form-result-ervenytelen-title')}}</div>                  
                  <div class="email-info-text--small text-container">
                    {{tr('winning-form-result-ervenytelen-text-1-1')}} <a :href="baseUrl+`documents/Jatekszabalyzat_${$store.state.currentLang}.pdf?` + jatekszabalyTimeStamp"
                         style="text-decoration:underline; color:black; font-weight:bold" target="_blank">{{tr('winning-form-result-ervenytelen-text-1-2')}}</a>
                    {{tr('winning-form-result-ervenytelen-text-1-3')}}<br/><br/>
                  </div>
                  <!--<p v-html="tr('winning-form-result-ervenytelen-text-2')"></p>    -->              
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mt-3 btn-primary--large" @click="backToForm">
                      {{tr('winning-form-result-new-code-btn')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import promoStatus from "@/config/promoStatus";
import CodeFillingWinnerformHero from '@/components/CodeFillingWinnerFormHero.vue'

export default {
  name: "FormExpiredAndRegistered",
  props: ['alreadyRegistered', 'formExpired', 'formDisabled', 'uploadedWinner', 'uploadedError',
    'winnerType'],
    data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  components:{
    CodeFillingWinnerformHero
  },
  methods: {
    backToForm() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
  },
  computed: {
    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
};
</script>
